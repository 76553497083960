import React from 'react'
import Helmet from 'react-helmet'
//import { navigate } from 'gatsby-link'
import Layout from '../../components/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Link} from 'gatsby'

export default class ShoppingFeeds extends React.Component {
  constructor(props) {
    super(props)
    this.state = {  }
  }

  render() {
    return (
      <Layout>
        <Helmet>
            <title>WesData | Partner Management</title>
        </Helmet>
        <section className="section" >
            <div className="container">
                <div className="content">
                    <div className="columns">
                        <div className="column is-12">
                            <h1>Partner Management</h1>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-3">
                            <div class="card">
                                <div class="card-image" style={{backgroundColor:'#406eb7',textAlign:'center',paddingTop:'1em',paddingBottom:'1em'}}>
                                    <FontAwesomeIcon icon="users" size="7x" color="#fff"/>
                                </div>
                                <div class="card-content">
                                    <div class="media">
                                        <div class="media-content">
                                            <p class="title is-4">Experience Counts</p>
                                        </div>
                                    </div>

                                    <div class="content">
                                    We know the ins and outs of shopping engines, and we use this knowledge to make sure that your shopping feeds are optimized to display under ideal circumstances with each of them.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column is-6">
                            <div class="card"><div class="card-content">
                            <div className="columns">
                                <div className="column is-2" style={{textAlign:'center'}}>
                                    <FontAwesomeIcon icon="handshake" size="2x" color="#406eb7"/>
                                </div>
                                <div className="column is-10" style={{textAlign:'left'}}>
                                    <h3>Relationships Matter</h3>
                                    <p>We've been working with most major shopping engines for as long as comparison shopping
                                       has existed. We maintain working relationships with with them to insure that your
                                       feed setup, tracking and issue resolution are smooth and hassle-free.
                                       If a problem comes up, we work quickly with partners to correct the issue
                                       and get you back up and running quickly.
                                    </p>
                                </div>
                            </div>
                            <div className="columns">
                                <div className="column is-2" style={{textAlign:'center'}}>
                                    <FontAwesomeIcon icon="shopping-cart" size="2x" color="#406eb7"/>
                                </div>
                                <div className="column is-10" style={{textAlign:'left'}}>
                                <a name="channels"><h3>Shopping Channels</h3></a>
                                    <ul>
                                        <li>Amazon</li>
                                        <li>Awin</li>
                                        <li>Bing</li>
                                        <li>CJ</li>
                                        <li>Connexity</li>
                                        <li>Criteo</li>
                                        <li>Ebay</li>
                                        <li>Facebook</li>
                                        <li>Google</li>
                                        <li>Kelkoo</li>
                                        <li>Pepperjam</li>
                                        <li>Pricegrabber</li>
                                        <li>Rakuten/Linkshare</li>
                                        <li>ShareASale</li>
                                        <li>Walmart</li>
                                        <li>And many others</li>
                                    </ul>
                                </div>
                            </div>
                            
                            </div></div>
                        </div>
                        <div className="column is-3">
                        <div class="card">
                                <div class="card-content">
                                    <div class="media">
                                        <div class="media-content">
                                            <p class="title is-4">More Services</p>
                                        </div>
                                    </div>

                                    <div class="content">
                                        <ul>
                                            <li>
                                                <Link to="/services/shopping-feeds">Product Feeds</Link>
                                            </li>
                                            <li>
                                                <Link to="/services/roi-analysis">Feed Analytics</Link>
                                            </li>
                                            <li>
                                                <Link to="/services/feed-optimization">Feed Optimization</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </Layout>
    )
  }
}
